import { loadProgressBar } from 'axios-progress-bar'
import { reward_wheel_axios } from '../main'

const getauthorizedstores = (request) => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/merchantadmin/getauthorizedstores', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getAllStates = () => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.get('/api/admin/getallstates')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const addEditCampaign = (request) => {
    return new Promise((res, rej) => {
        axios.post('/Integrator/addeditcampaign', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const getCampaignData = (request) => {
    return new Promise((res, rej) => {
        axios.post('/Integrator/getsinglecampaigndata', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const assignToCampaign = (request) => {
    return new Promise((res, rej) => {
        axios.post('/Integrator/awardpoints', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getMerchantCampaignList = (request) => {
    return new Promise((res, rej) => {
        axios.post("Integrator/getmechantcampaignlists", request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
}

const reassignCampaignPoints = (request) => {
    return new Promise((res, rej) => {
        axios.post('/Integrator/reassignpoints', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getAllCorporateParents = () => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.get('/api/admin/getallcorporateparents')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getAllStores = (states, cp_ids) => {
    loadProgressBar({}, reward_wheel_axios)
    return new Promise((res, rej) => {
        reward_wheel_axios.defaults.headers.common["Authorization"] = 'Bearer ' + localStorage.getItem("token");
        reward_wheel_axios.post('/api/admin/getauthorizedstores', { state: states, id: cp_ids })
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    getauthorizedstores,
    addEditCampaign,
    getCampaignData,
    assignToCampaign,
    getMerchantCampaignList,
    reassignCampaignPoints,
    getAllStates,
    getAllCorporateParents,
    getAllStores
};