<template>
  <div class="card">
    <div v-if="login_with_pin" class="card-body login-card-body">
      <p class="login-box-msg">Quick Access PIN to access Merchant Admin</p>
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          v-model="login_pin"
          maxlength="5"
          minlength="5"
          inputmode="numeric"
          @input="filterNonNumeric"
          placeholder="Quick Access PIN"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          
        </div>
        <!-- /.col -->
        <div class="col-4">
          <button
            type="button"
            class="btn btn-success btn-block"
            :disabled="isSubmitDisabled"
            @click="loginWthPin"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="!login_with_pin && !forgot_password" class="card-body login-card-body">
      <p class="login-box-msg">Sign in to access Merchant Admin</p>
      <div class="input-group mb-3">
        <input
          type="email"
          class="form-control"
          v-model="email"
          placeholder="Email"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-envelope"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input
          type="password"
          class="form-control"
          v-model="password"
          placeholder="Password"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="icheck-primary">
            <a class="cursor-pointer" @click="toggleForm()">Forgot Password?</a>
          </div>
        </div>
        <!-- /.col -->
        <div class="col-4">
          <button
            type="button"
            class="btn btn-success btn-block"
            @click="login"
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
    <!-- /.frogot password-card-body -->
    <div v-else-if="!login_with_pin" class="card-body login-card-body">
      <p class="login-box-msg">
        A Temporary Password Will Be Sent To Your Email
      </p>
      <div class="input-group mb-3">
        <input
          type="email"
          class="form-control"
          v-model="forgot_password_email"
          placeholder="Email"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-envelope"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="icheck-primary">
            <a class="cursor-pointer" @click="toggleForm"
              >Remember your password? Sign In instead.</a
            >
          </div>
        </div>
        <!-- /.col -->
        <div class="col-6">
          <!-- <button
            type="button"
            class="btn btn-success btn-block"
            @click="forgotPassword()"
          >
            Send
          </button> -->
          <TimerValidation
          v-model="timeLeft"
          label="Send"
          counter-label="Send After"
          :onClick="forgotPassword"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/auth.js";
import Constants from "@/common/constant.js";
import TimerValidation from './TimerValidation.vue'

export default {
  data() {
    return {
      email: "",
      password: "",
      login_pin: "",
      constants: Constants,
      forgot_password_email: "",
      forgot_password: false,
      login_with_pin: false,
      timeLeft: null
    };
  },
  components:{
    TimerValidation
  },
  created() {
    if (localStorage.getItem("token") !== null) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
    if (localStorage.getItem("transaction_modification_id")) {
      localStorage.removeItem("transaction_modification_id");
    }
    if (this.$router.currentRoute.fullPath.match(/^\/login\/((?:[^\/]+?))(?:\/(?=$))?$/i)) {
      localStorage.setItem("transaction_modification_id", this.$router.currentRoute.params.id);
      this.login_with_pin = true;
    }
  },
  computed: {
    isSubmitDisabled() {
      return this.login_pin.length != 5; // Disable the button if input field length is less than 5
    }
  },
  methods: {
    toggleForm() {
      if (!this.forgot_password) {
        this.forgot_password = true;
      } else {
        this.forgot_password = false;
      }
    },
    /**
     * calling login API
     */
    login() {
      let self = this;
      let request = {
        email: self.email,
        password: self.password,
      };
      if (localStorage.getItem("transaction_modification_id")) {
        request['transaction_modification_intent_id'] = localStorage.getItem("transaction_modification_id");
      }
      api
        .login(request)
        .then((response) => {
          if (response.code == 200) {
            //checking for only CP and RM login
            console.log(response.data);
            if (
              response.data.role_name != self.constants.role_regional_manager &&
              response.data.role_name != self.constants.role_corporate_parent &&
              response.data.role_name != self.constants.role_accountant &&
              response.data.role_name != self.constants.role_store_manager && 
              response.data.role_name != self.constants.role_new_regional_manager && 
              response.data.role_name != self.constants.role_new_remotepay_manager &&
              response.data.role_name != self.constants.role_new_brand_manager
            ) {
              error("Unauthorized User!");
              return false;
            }
            //storing the authorization token into the local storage for future use
            localStorage.setItem("token", response.data.token);
            sessionStorage.setItem("token", response.data.token);
            localStorage.setItem("store_user", JSON.stringify(response.data));
            localStorage.setItem("disable_store_count", response.data.disable_store_count);
            localStorage.setItem("sposor_store_count", response.data.sposor_store_count);
            
            axios.defaults.headers.common[
              "Authorization"
            ] = localStorage.getItem("token");
            success(response.message);
            if (localStorage.getItem("transaction_modification_id")) {
              self.$router.push("/remotepaytransactionshistory");
            }else{
              if (response.data.sposor_store_count > 0) {
                self.$router.push("/sponsordashboard"); 
              } else if (response.data.role_name == self.constants.role_new_remotepay_manager) {
                self.$router.push("/ecommercedashboard"); 
              } else if (response.data.role_name == self.constants.role_new_brand_manager) {
                self.$router.push("/campaignlist"); 
              } else {
                self.$router.push("/dashboard");  
              }
              
            }
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    forgotPassword() {
      let self = this;
      let request = {
        email: self.forgot_password_email,
      };
      api
        .forgotPassword(request)
        .then((response) => {
          if (response.code == 200) {
            if(response.data != null && response.data.time_left){
              self.timeLeft = response.data.time_left
            }
            self.forgot_password_email = "";
            success(response.message);
          } else {
            if(response.data != null && response.data.time_left){
              self.timeLeft = response.data.time_left
            }
            error(response.message);
          }
        })
        .catch((err) => {
          if(err.response.data.data != null && err.response.data.data.time_left){
            self.timeLeft = err.response.data.data.time_left
          }
          console.log(err);
        });
    },
    /**
     * calling login with PIN API
     */
    loginWthPin() {
      let self = this;
      let request = {
        login_pin: self.login_pin,
      };
      if (localStorage.getItem("transaction_modification_id")) {
        request['transaction_modification_intent_id'] = localStorage.getItem("transaction_modification_id");
      }
      api
        .loginWthPin(request)
        .then((response) => {
          if (response.code == 200) {
            if (
              response.data.role_name != self.constants.role_regional_manager &&
              response.data.role_name != self.constants.role_corporate_parent &&
              response.data.role_name != self.constants.role_accountant &&
              response.data.role_name != self.constants.role_store_manager && 
              response.data.role_name != self.constants.role_new_regional_manager && 
              response.data.role_name != self.constants.role_new_remotepay_manager
            ) {
              error("Unauthorized User!");
              return false;
            }
            //storing the authorization token into the local storage for future use
            localStorage.setItem("token", response.data.token);
            sessionStorage.setItem("token", response.data.token);
            localStorage.setItem("store_user", JSON.stringify(response.data));
            localStorage.setItem("disable_store_count", response.data.disable_store_count);
            localStorage.setItem("sposor_store_count", response.data.sposor_store_count);
            
            axios.defaults.headers.common[
              "Authorization"
            ] = localStorage.getItem("token");
            success(response.message);
            if (localStorage.getItem("transaction_modification_id")) {
              self.$router.push("/remotepaytransactionshistory");
            }else{
              if (response.data.sposor_store_count > 0) {
                self.$router.push("/sponsordashboard"); 
              } else if (response.data.role_name == self.constants.role_new_remotepay_manager) {
                self.$router.push("/ecommercedashboard"); 
              } else {
                self.$router.push("/dashboard");  
              }
              
            }
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterNonNumeric() {
      // Replace non-numeric characters with an empty string
      this.login_pin = this.login_pin.replace(/[^0-9]/g, "");
    },
  },
  mounted() {
    document.title = "CanPay - Merchant Login";
  },
};
</script>
