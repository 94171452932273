<template>
    <div>
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6"></div>
          </div>
        </div>
      </section>
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <b-button
                  variant="outline-success"
                  style="margin-top: -48px"
                  @click="openModal('add')"
                >
                  <i class="fas fa-plus"></i> Assign Campaign
                </b-button>
              <div class="card card-success">
                <div class="card-header">
                  <h3 class="card-title">Campaign Rewards History</h3>
                </div>
                <div class="loading" v-if="loading">
                  <hour-glass></hour-glass>
                </div>   
                <div class="card-body">
                  <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                            <input
                              class="start-date form-control"
                              placeholder="Start Date"
                              id="start-date"
                              onkeydown="return false"
                              autocomplete="off"
                            />
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <input
                            class="end-date form-control"
                            placeholder="End Date"
                            id="end-date"
                            onkeydown="return false"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                  </div>
                </div>
                <div class="card-footer">
                    <button
                      type="button"
                      class="btn btn-success"
                      @click="search()"
                    >
                      Search
                    </button>
                    <button
                      style="margin-left: 15px;"
                      v-show="showReassignButton"
                      type="button"
                      class="btn btn-success"
                      @click="reAssignPoints()"
                    >
                      Re-assign
                    </button>
                </div>  
                <div class="card-body" >
                  <table
                      id="brandListTable"
                      class="table"
                      style="width: 100%; white-space: normal"
                    >
                      <thead>
                        <tr>
                          <th width="5%%"></th>
                          <th width="30%">Campaign ID</th>
                          <th width="30%">Campaign Name</th>
                          <th width="30%">Source Phone</th>
                          <th width="30%">Destination Phone</th>
                          <th width="30%">Amount</th>
                          <th width="30%">Points</th>
                          <th width="10%">Status</th>
                          <th width="15%">Created On</th>
                        </tr>
                      </thead>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <!-- SM Modal Start -->
      <b-modal
        id="sm-modal"
        ref="modal"
        :header-text-variant="headerTextVariant"
        :title="modalTitle"
        ok-title="Save"
        ok-variant="success"
        cancel-variant="outline-secondary"
        @ok="handleOk"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
      >
      <form ref="cashbackfrm">
      <div class="card">
        <div class="card-body row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Phone:<span class="red">*</span></label> <br>
              <input 
                type="tel" 
                class="form-control" 
                v-model="phone" 
                :name="'phone'" 
                v-validate="'required|no_spaces|digits:10'" 
                placeholder="Enter phone number" 
                >
              <span v-show="errors.has('phone')" class="text-danger">{{ errors.first('phone') }}</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
                <label for="rewardAmount" class="font-weight-bold">Amount:<span class="text-danger">*</span></label>
                <br>
                <b-input-group>
                <b-input-group-prepend>
                    <span class="input-group-text">$</span> <!-- Change to span for consistency -->
                </b-input-group-prepend>
                <b-form-input
                    v-model="reward_amount"
                    id="rewardAmount"
                    placeholder="Enter Amount"
                    autocomplete="off"
                    @input="isNumber($event)"
                    @keypress="isNumber($event)"
                    :class="{'is-invalid': errors.has('reward_amount')}"
                ></b-form-input>
                </b-input-group>
                <span v-show="errors.has('reward_amount')" class="text-danger mt-1">
                  The reward amount field is required
                </span>
            </div>
            </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Select Campaign </label> <br>
              <multiselect
                id="store"
                name="store"
                v-model="selectedCampaign" 
                :options="allCampaigns"
                track-by="campaign_id"
                label="campaign_name"
                placeholder="Select Campaign"
                selectLabel
                deselectLabel
                :close-on-select="true"
                :clear-on-select="true"
                v-validate="selectedCampaign && selectedCampaign.status === 'Active' ? '' : 'required'"
                @select="onSelectCampaign" 
                ></multiselect>
              <span v-show="errors.has('store')" class="text-danger">{{ errors.first('store') }}</span>
            </div>
          </div>
        </div>
      </div>    
      </form>
      </b-modal>
      <!-- SM Modal End -->
      <!-- Reassign Modal Start -->
      <b-modal
        id="reassign-modal"
        ref="modal"
        :header-text-variant="headerTextVariant"
        :title="modalTitle"
        ok-title="Save"
        ok-variant="success"
        cancel-variant="outline-secondary"
        @ok="handleReassign"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
      >
      <form ref="cashbackfrm">
      <div class="card">
        <div class="card-body row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Phone:<span class="red">*</span></label> <br>
              <input 
                type="tel" 
                class="form-control" 
                v-model="to_phone_no" 
                :name="'to_phone_no'" 
                v-validate="'required|no_spaces|digits:10'" 
                placeholder="Enter the phone number to reassign points" 
                >
              <span v-show="errors.has('to_phone_no')" class="text-danger">{{ errors.first('to_phone_no') }}</span>
            </div>
          </div>
        </div>
      </div>    
      </form>
      </b-modal>
      <!-- Reassign Modal End -->
    </div>
  </template>
  <script>
  import campaign from "@/api/campaign.js";
  import api from "@/api/cashback.js";
  import apiuser from "@/api/user.js";
  import moment from "moment";
  import { HourGlass } from "vue-loading-spinner";
  import Constants from "@/common/constant.js";
  export default {
    data() {
      return {
        userDetails: JSON.parse(localStorage.getItem("store_user")),
        modalTitle: "",
        headerTextVariant: "light",
        loading: false,
        type: '',
        edit_id: '',
        campaignDetails:{
            campaign_id:"",
            campaign_name:"",
            tax:"",
            status_id:"",
            use_as_generic_points:1,
            stores: [],
        },
        selectedCampaign: null,
        activeStatusID:"",
        inactiveStatusID:"",
        states: [],
        selectedStates: [],
        allCampaigns: [],
        selectedStore: [],
        storeList: [],
        reward_amount: null,
        phone: null,
        checkedRows:[],
        showReassignButton: false,
        to_phone_no: null,
      };
    },
    components: {
      HourGlass,
    },
    watch: {
      selectedStates: {
        handler() {
          var self = this;
          if (self.selectedStates.length > 0) {
            this.getAuthorizedStores();
          }
        },
        deep: true
      }
    },
    beforeDestroy(){
      $(document).off('click', '.editCampaignDetails');
    },
    mounted() {
      var self = this;
    $("#start-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
      startDate: new Date('2021-07-01')
    });
    $("#end-date").datepicker({
      format: "mm/dd/yyyy",
      autoclose: true,
      todayHighlight: true,
    });
    $("#start-date").datepicker("setDate", new Date());
    $("#end-date").datepicker("setDate", new Date());
      self.$validator.extend('no_spaces', {
        validate: value => !/\s/.test(value),
        getMessage: field => `The ${field} should not contain spaces.`
      });
      // setTimeout(function () {
      //   self.loadDT();
      // }, 1000);
      self.getMerchantStates();
      self.getStatus();
      self.editCampaignDetails();
      document.title = "CanPay - Campaign";
      self.getMerchantCampaignList();
    },
    created(){
      this.enableCheckBox()
    },
    methods: {
      enableCheckBox() {
      var self = this;
      $(".enableCheckBox").off("change");
      $(document).on("click", ".enableCheckBox", function (e) {
        var rowId = $(this).attr("data-row-id");
        if ($(this).is(":checked")) {
          // If checked, push the row data to the checkedRows array
          self.checkedRows.push(rowId);
        } else {
          // If unchecked, remove the row data from the checkedRows array
          self.checkedRows = self.checkedRows.filter((row) => row !== rowId);
        }
        if(self.checkedRows.length > 0) {
          self.showReassignButton = true;
        } else {
          self.showReassignButton = false;
        }
      })
    },
      addOrRemoveId(id){
        const checkbox = document.getElementById('checkbox-' + id);
        if (checkbox.checked) {
          console.log("Checked:", id);
        } else {
          console.log("Unchecked:", id);
        }
      },
      search(){
        let self = this;
        self.showReassignButton = false;
        self.loadDT();
      },
      getMerchantCampaignList(){
        let self = this;
        campaign
        .getMerchantCampaignList()
        .then((res) => {
          self.allCampaigns = res.data;
        })
        .catch((err) => {
          error(err.response.data.message);
        })
      },
      loadDT: function () {
        var self = this;    
        if (moment($("#start-date").val()).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
          error("The start date cannot be in the future.");
          return;
        }
        if (moment($("#end-date").val()).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
          error("The end date cannot be from future.");
          return;
        }
        if (moment($("#start-date").val()).format("YYYY-MM-DD") > moment($("#end-date").val()).format("YYYY-MM-DD")) {
          error("The end date is not greater than the start date.");
          return;
        }  
        // Get the values from the inputs
        const startDate = moment(moment($("#start-date").val()).format("YYYY-MM-DD"));
        const endDate = moment(moment($("#end-date").val()).format("YYYY-MM-DD"));
        // Calculate the difference in days
        const dateDifference = endDate.diff(startDate, 'days');
        if(dateDifference>process.env.VUE_APP_CAMPAIGN_REWARD_HISTORY){
            error("The maximum of "+process.env.VUE_APP_CAMPAIGN_REWARD_HISTORY+" days of date range is allowed");
            return;
        }
        $("#brandListTable").DataTable({
          pagingType: "simple_numbers",
          processing: true,
          serverSide: true,
          destroy: true,
          columnDefs: [
            { orderable: false, targets: [3, 4] },
            { className: "dt-left break-all", targets: [0, 1, 2] },
            { className: "dt-center", targets: [3] },
            { className: "dt-center", targets: [4] },
          ],
          order: [[1, "asc"], [2, "desc"]],
          orderClasses: false,
          language: {
            processing:
              '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
            emptyTable: "No Campaign Assign History Available.",
            search: "_INPUT_",
            searchPlaceholder: "Search records",
            oPaginate: {
              sNext: '<i class="fas fa-angle-double-right"></i>',
              sPrevious: '<i class="fas fa-angle-double-left"></i>',
            },
            sLengthMenu:
              "<label class='label_dropdown_dt'>Per page</label> _MENU_",
          },
  
          ajax: {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
            url: process.env.VUE_APP_API_URL + "/Integrator/getcampaignpointshistory",
            type: "POST",
            data: { _token: "{{csrf_token()}}", start_date: moment($("#start-date").val()).format("YYYY-MM-DD"), end_date: moment($("#end-date").val()).format("YYYY-MM-DD") },
            dataType: "json",
            dataSrc: function (result) {
              return result.data;;
            },
            error: function (request) {
              if (
                request.responseJSON.code == 401 &&
                request.responseJSON.data != null
              ) {
                localStorage.setItem("token", request.responseJSON.data);
                self.loadDT();
              }else{
                error(Constants.datatable_error);
                $('#brandListTable_processing').hide();
              }
            },
          },
          columns: [
            {
              render: function (data, type, full, meta) {
                if (full.status == 'Unassigned') {
                  return (
                  '<input type="checkbox" class="enableCheckBox" data-row-id="'+full.edit_id+'" name="campaignCheckbox" value="' + full.edit_id + '"/>'
                );
                } else {
                  return '';
                }
                
              },
            },
            { data: "campaign_id"},
            { data: "campaign_name"},
            { data: "phone"},
            { data: "to_phone_no"},
            { data: "amount"},
            { data: "points"},
            { data: "status"},
            { data: "created_at"}
          ],
        });
  
        $("#brandListTable").on("page.dt", function () {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          $("th:first-child").focus();
        });
  
        //Search in the table only after 3 characters are typed
        // Call datatables, and return the API to the variable for use in our code
        // Binds datatables to all elements with a class of datatable
        var dtable = $("#brandListTable").dataTable().api();
  
        // Grab the datatables input box and alter how it is bound to events
        $(".dataTables_filter input")
        .unbind() // Unbind previous default bindings
        .bind("input", function(e) { // Bind our desired behavior
            // If the length is 3 or more characters, or the user pressed ENTER, search
            if(this.value.length >= 3 || e.keyCode == 13) {
                // Call the API search function
                dtable.search(this.value).draw();
            }
            // Ensure we clear the search if they backspace far enough
            if(this.value == "") {
                dtable.search("").draw();
            }
            return;
        });
      },
      handleOk(bvModalEvt) {
        var self = this;
        // Prevent modal from closing
        bvModalEvt.preventDefault();
        // Trigger submit handler
        self.save();
      },
      reAssignPoints(){
        var self = this;
        self.$bvModal.show("reassign-modal");
      },
      handleReassign(bvModalEvt) {
        var self = this;
        // Prevent modal from closing
        bvModalEvt.preventDefault();
        // Trigger submit handler
        self.reassign();
      },
      reassign(){
        var self = this;
        var request = {
          to_phone_no: self.to_phone_no,
          unassigned_points: self.checkedRows,
        };
        campaign
        .reassignCampaignPoints(request)
        .then(response => {
          if(response.code == 200){
            success(response.message);
            self.hideModal('reassign-modal');
            self.loadDT();
            // Reset the fields
            self.campaignDetails = [];
            self.selectedStore = [];
            self.selectedCampaign = null;
            self.to_phone_no = null;
            self.checkedRows = [];
            self.showReassignButton = false;
            self.$validator.reset();
          }
        })
        .catch(err => {
            if(err.response.data.message){
                error(err.response.data.message);
            }else{
                error(err);
            }
        });
      },
      save(){
        var self = this;
        self.$validator.validateAll().then(result => {
            if (result) {
              self.assignCampaign();
            }
        })
        .catch(err => {
            error(err);
        });
      },
      editCampaignDetails(){
        var self = this;
        $(document).on("click", ".editCampaignDetails", function (e) {
          self.edit_id = $(e.currentTarget).attr("data-user-id");
          self.openModal('edit');
          self.getCampaignData();
        });
      },
      assignCampaign() {
        var self = this;
        
        var request = {
          campaign_id: self.selectedCampaign.campaign_id,
          consumer_phone: self.phone,
          amount: self.reward_amount,
        };
        campaign
        .assignToCampaign(request)
        .then(response => {
          if(response.code == 200){
            success(response.message);
            self.hideModal('sm-modal');
            self.loadDT();
            // Reset the fields
            self.campaignDetails = [];
            self.selectedStore = [];
            self.selectedCampaign = null;
            self.$validator.reset();
          }
        })
        .catch(err => {
            if(err.response.data.message){
                error(err.response.data.message);
            }else{
                error(err);
            }
        });
      },
      isNumber: function (evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        this.reward_amount = this.reward_amount.replace(/[^\d.](?!\.)|(?<=\..*)(?!\.)/g, '');
        // first number should't be dot
        if(this.reward_amount.length == 0 && charCode == 46){
          this.reward_amount = 0.00;
        }
        // only allow positive number
        if ((charCode < 48 || charCode > 57) && (charCode !== 46 || this.reward_amount.indexOf('.') !== -1)) { // 46 is dot
            evt.preventDefault();
        }
        // restrict to 2 decimal place
        else if (this.reward_amount !== null && this.reward_amount.indexOf('.') > -1 && (this.reward_amount.split('.')[1].length > 1)) {
          evt.preventDefault();
        }
        else {
          return true;
        }
      },
      onSelectCampaign(selected) {
        this.selectedCampaign = selected;
      },
      hideModal(modal){
        var self = this;
        self.$bvModal.hide(modal);
      },
      getCampaignData() {
        var self = this;
        
        var request = {
          id: self.edit_id
        };
      
        campaign
        .getCampaignData(request)
        .then(response => {
          if(response.code == 200){
            self.selectedStore = response.data.stores;
            self.campaignDetails.id = response.data.campaign.id;
            self.campaignDetails.campaign_id = response.data.campaign.campaign_id;
            self.campaignDetails.campaign_name = response.data.campaign.campaign_name;
            self.campaignDetails.status_id = response.data.campaign.status_id;
            self.campaignDetails.use_as_generic_points = response.data.campaign.use_as_generic_points == 0 ? 1 : 0;
            self.setStates(response.data.stores);
          }
        })
        .catch(err => {
              if(err.response.data.message){
                  error(err.response.data.message);
              }else{
                  error(err);
              }
          });
      },
      setStates(campaignStores){
          this.selectedStates = []
          campaignStores.forEach(campaignStore => {
              this.selectedStates.push(this.states[0].data.find(state => state.state == campaignStore.state))
          });
  
          // remove duplicates from state array
          this.selectedStates = this.selectedStates.filter((value, index, self) =>
              index === self.findIndex((t) => (
                  t.state === value.state
              ))
          )
      },
      //get the autorized merchant list
      getAuthorizedStores() {
        var self = this;
        var request = {
          state: self.selectedStates
        };
        campaign
          .getauthorizedstores(request)
          .then(function (response) {
            if (response.code == 200) {
              self.storeList = response.data;
              if (self.type != "edit") {
                self.selectedStore = self.storeList;
              }
              self.storeList = [{
                  label: 'Select All Stores',
                  data: self.storeList
              }]
            } else {
              error(response.message);
            }
          })
          .catch(function (error) {
            error(error);
             });
      },
      getStatus() {
      var self = this;
      api
        .getStatus()
        .then((response) => {
          if ((response.code = 200)) {
            response.data.forEach((field, index) => {
              if (field.code == '204') {
                self.activeStatusID = field.id;
              } else if (field.code == '205') {
                self.inactiveStatusID = field.id;
              }
            });
          } else {
            error(response.message);
          }
        })
        .catch((err) => {
          error(err);
        });
      },
      //get the autorized merchant list
      getMerchantStates() {
        var self = this;
        api
          .getMerchantStates()
          .then(function (response) {
            if (response.code == 200) {
              self.states = [{
                  label: 'Select All States',
                  data: response.data
              }]
            } else {
              error(response.message);
            }
          })
          .catch(function (error) {
            error(error);
             });
      },
      openModal(type) {
        var self = this;
        // self.selectedStore = [];
        // self.selectedStates = [];
        self.type = type;
        if (type == "edit") {
          self.modalTitle = "Edit Campaign";
          self.$bvModal.show("sm-modal");
        } else {
          self.modalTitle = "Assign Campaign";
          // Reset Form Fields
          self.phone = '';
          self.reward_amount = '';
          self.selectedCampaign = null;
          self.$bvModal.show("sm-modal");
        }
      },
      stateCustomName (data) {
        return data.state
      },
      customStoreSelectLabel(details) {
        return details && details.campaign_name ? store.campaign_name : '';
      },
    }
  };
  </script>
  <style>
  .form-group {
    position: relative;
  }
  
  .input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Prevent the icon from blocking input interactions */
    color: #888; /* Customize color as needed */
    font-size: 16px; /* Adjust font size as needed */
  }
  
  .form-control.pr-4 {
    padding-right: 30px; /* Make space for the icon */
  }
  
  </style>