<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"></div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <b-button
                variant="outline-success"
                style="margin-top: -48px"
                @click="openModal('add')"
              >
                <i class="fas fa-plus"></i> Add Campaign
              </b-button>
            <div class="card card-success">
              <div class="card-header">
                <h3 class="card-title">Campaign Master</h3>
              </div>
              <div class="loading" v-if="loading">
                <hour-glass></hour-glass>
              </div>   
              <div class="card-body">
                <table
                    id="brandListTable"
                    class="table"
                    style="width: 100%; white-space: normal"
                  >
                    <thead>
                      <tr>
                        <th width="20%">Campaign ID</th>
                        <th width="20%">Campaign Name</th>
                        <th width="20%">Brand Name</th>
                        <th width="10%">Status</th>
                        <th width="15%">Created On</th>
                        <th width="15%">Action</th>
                      </tr>
                    </thead>
                  </table>
              </div>           
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- SM Modal Start -->
    <b-modal
      id="sm-modal"
      ref="modal"
      :header-text-variant="headerTextVariant"
      :title="modalTitle"
      ok-title="Save"
      ok-variant="success"
      cancel-variant="outline-secondary"
      @ok="handleOk"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
    <div v-if="!fetchingLoader">
    <form ref="cashbackfrm">
    <div class="card">
      <div class="card-body row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Campaign ID:<span class="red">*</span></label> <br>
            <input type="text" class="form-control" v-model="campaignDetails.campaign_id" :name="'campaign_id'"  v-validate="'required|no_spaces'">
            <span v-show="errors.has('campaign_id')" class="text-danger">{{ errors.first('campaign_id') }}</span>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Name:<span class="red">*</span></label> <br>
            <input type="text" class="form-control" v-model="campaignDetails.campaign_name" :name="'campaign_name'"  v-validate="'required'">
            <span v-show="errors.has('campaign_name')" class="text-danger">The campaign name field is required</span>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label for="">Select Brand <span class="red">*</span></label> <br>
            <multiselect
              id="store"
              name="store"
              v-model="selectedBrand"
              placeholder="Select Brand"
              label="retailer"
              track-by="id"
              :options="brandList"
              :multiple="false"
              :searchable="true"
              :show-labels="false"
              v-validate="'required'"
            ></multiselect>
            <span v-show="errors.has('store')" class="text-danger">{{ errors.first('store') }}</span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="reward_wheel">
                States:
                <span v-if="(campaignDetails.status_id == activeStatusID)" class="red">*</span>
            </label>
            <multiselect
              name="state"
              v-model="selectedStates"
              :options="states"
              :custom-label="stateCustomName"
              group-values="data"
              group-label="label"
              :group-select="true"
              ref="state"
              :multiple="true"
              placeholder="Select state"
              track-by="state"
              v-validate="'required'"
            >
            </multiselect>
            <span v-show="errors.has('state')" class="text-danger">{{ errors.first('state') }}</span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="reward_wheel"> Corporate Parents <span class="red">*</span></label>
            <multiselect
            v-model="selectedCorporateParents"
            :options="corporateParents"
            :custom-label="cpCustomName"
            group-values="data"
            group-label="label"
            :group-select="true"
            ref="corporate_parent"
            :multiple="true"
            placeholder="Select corporate parents"
            track-by="first_name">
            </multiselect>
            <input
            name="corporate_parent"
            type="hidden"
            v-validate="'required'"
            v-model="selectedCorporateParents"
            />
            <span v-show="errors.has('corporate_parent')" class="text-danger">{{ getFormatedErrorMessage('corporate_parent', 'Corporate Parent') }}</span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="reward_wheel">
                Stores
                <span class="red">*</span>
            </label>
            <multiselect
            :disabled="disableStore"
            v-model="selectedStores"
            :options="stores"
            :custom-label="storeCustomName"
            group-values="data"
            group-label="label"
            :group-select="true"
            ref="stores"
            :multiple="true"
            placeholder="Select stores"
            track-by="retailer">
            </multiselect>
            <input
            name="stores"
            type="hidden"
            v-model="stores"
            v-validate="'required'"
            />
            <span v-show="errors.has('stores')" class="text-danger">{{ getFormatedErrorMessage('stores', 'Stores') }}</span>
          </div>
        </div>
        
        <div class="col-md-12">
          <label class="switch"><input type="checkbox" id="status_id" name="status_id" v-model="campaignDetails.status_id" :true-value="activeStatusID" :false-value="inactiveStatusID" class="enable-employee-login"><span class="slider round"></span></label>
            <label for="status_id" class="ml-1">Status: <span v-if="campaignDetails.status_id == activeStatusID">Active</span><span v-else>Inactive</span></label>
        </div>
        <!-- Brand points are currently being treated as generic points, but later, if they become non-generic, their code will need to be opened  -->
        <!-- 
        <div class="col-md-12">
          <label class="switch"><input type="checkbox" id="use_as_generic_points" name="use_as_generic_points" v-model="campaignDetails.use_as_generic_points" true-value="1" false-value="0" class="enable-employee-login"><span class="slider round"></span></label> <label for="use_as_generic_points">Corporate Parent Specific Points
            <sup style="margin-left: 0px;top: -7px;"><a style="color: #000;" href="javascript:void(0)" v-b-tooltip.hover title="If this toggle switch is off, points can be used at any store in CanPay. If it is on, points can only be used at all stores within the Corporate Parent.">
                <svg fill="#000000" width="15px" viewBox="-1 0 19 19" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg"><path d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"/></svg>
            </a></sup>
          </label>
        </div> -->
      </div>
    </div>    
    </form>
    </div>
    <div v-else class="d-flex flex-column justify-content-center align-items-center">
        <i class="fa fa-spinner fa-spin fa-3x fa-fw spinner-default"></i>
        <p>{{fetchingLoaderText}}</p>
    </div>
    </b-modal>
    <!-- SM Modal End -->
  </div>
</template>
<script>
import campaign from "@/api/campaign.js";
import api from "@/api/cashback.js";
import apiuser from "@/api/user.js";
import moment from "moment";
import { HourGlass } from "vue-loading-spinner";
import Constants from "@/common/constant.js";

export default {
  data() {
    return {
      userDetails: JSON.parse(localStorage.getItem("store_user")),
      modalTitle: "",
      headerTextVariant: "light",
      loading: false,
      type: '',
      edit_id: '',
      campaignDetails:{
        id:"",
        brand_id:"",
        campaign_id:"",
        campaign_name:"",
        tax:"",
        status_id:"",
        use_as_generic_points:1,
        stores: [],
      },
      activeStatusID:"",
      inactiveStatusID:"",
      states: [],
      selectedStates: [],
      allCampaigns: [],
      selectedBrand: [],
      brandList: [],
      selectedCorporateParents: [],
      corporateParents: [],
      disableStore: true,
      stores: [],
      fetchingLoader: false,
      fetchingLoaderText: "",
      selectedStores: [],
    };
  },
  components: {
    HourGlass,
  },
  watch: {
    selectedCorporateParents: {
      handler() {
        var self = this;
        if (self.selectedStates.length > 0 && self.selectedCorporateParents.length > 0) {
          self.disableStore = false;
          var cps = self.selectedCorporateParents.map(item => ({ id: item.user_id }))

          var selectedStore = [];
          self.selectedCorporateParents.forEach(cp => {
              self.selectedStores.forEach(store => {
                  if(store.corporate_parent_id == cp.user_id){
                      selectedStore.push({store_id: store.id})
                  }
              });
          });
          self.getAllStores(self.selectedStates, cps, selectedStore)
        }
      },
      deep: true
    },
    selectedStates: {
      handler() {
        var self = this;
        if (self.selectedStates.length > 0 && self.selectedCorporateParents.length > 0) {
          self.disableStore = false;
          var cps = self.selectedCorporateParents.map(item => ({ id: item.user_id }))
          
          var selectedStore = [];
          self.selectedStates.forEach(state => {
              self.selectedStores.forEach(store => {
                if(store.state == state.state){
                    selectedStore.push({store_id: store.id})
                }
              });
          });
          self.getAllStores(self.selectedStates, cps, selectedStore)
        }
      },
      deep: true
    }
  },
  beforeDestroy(){
    $(document).off('click', '.editCampaignDetails');
  },
  mounted() {
    var self = this;
    self.$validator.extend('no_spaces', {
      validate: value => !/\s/.test(value),
      getMessage: field => `The ${field} should not contain spaces.`
    });
    setTimeout(function () {
      self.loadDT();
    }, 1000);
    
    self.getAllCorporateParents();
    self.getAuthorizedStores();
    self.getAllStates();
    self.getStatus();
    self.editCampaignDetails();
    document.title = "CanPay - Campaign";
  },
  methods: {
    loadDT: function () {
      var self = this;      
      $("#brandListTable").DataTable({
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        destroy: true,
        columnDefs: [
          { orderable: false, targets: [4, 5] },
          { className: "dt-left break-all", targets: [0, 1, 2] },
          { className: "dt-center", targets: [3,4,5] },
        ],
        order: [[1, "asc"], [3, "asc"]],
        orderClasses: false,
        language: {
          processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> ',
          emptyTable: "No Cashback Program Available.",
          search: "_INPUT_",
          searchPlaceholder: "Search records",
          oPaginate: {
            sNext: '<i class="fas fa-angle-double-right"></i>',
            sPrevious: '<i class="fas fa-angle-double-left"></i>',
          },
          sLengthMenu:
            "<label class='label_dropdown_dt'>Per page</label> _MENU_",
        },

        ajax: {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          url: process.env.VUE_APP_API_URL + "/Integrator/getcampaignlists",
          type: "POST",
          data: { _token: "{{csrf_token()}}" },
          dataType: "json",
          dataSrc: function (result) {
            self.allCampaigns = result.data;
            return self.allCampaigns;
          },
          error: function (request) {
            if (
              request.responseJSON.code == 401 &&
              request.responseJSON.data != null
            ) {
              localStorage.setItem("token", request.responseJSON.data);
              self.loadDT();
            }else{
              error(Constants.datatable_error);
              $('#brandListTable_processing').hide();
            }
          },
        },
        columns: [
          { data: "campaign_id"},
          { data: "campaign_name"},
          { data: "brand_name"},
          { data: "status"},
          { data: "created_at"},
          {
            render: function (data, type, full, meta) {
              return (
                '<b-button data-user-id="' +
                full.edit_id +
                '" class="editCampaignDetails custom-edit-btn" title="Edit Campaign" variant="outline-success"><i class="nav-icon fas fa-edit"></i></b-button>'
              );
            },
          }
        ],
      });

      $("#brandListTable").on("page.dt", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        $("th:first-child").focus();
      });

      //Search in the table only after 3 characters are typed
      // Call datatables, and return the API to the variable for use in our code
      // Binds datatables to all elements with a class of datatable
      var dtable = $("#brandListTable").dataTable().api();

      // Grab the datatables input box and alter how it is bound to events
      $(".dataTables_filter input")
      .unbind() // Unbind previous default bindings
      .bind("input", function(e) { // Bind our desired behavior
          // If the length is 3 or more characters, or the user pressed ENTER, search
          if(this.value.length >= 3 || e.keyCode == 13) {
              // Call the API search function
              dtable.search(this.value).draw();
          }
          // Ensure we clear the search if they backspace far enough
          if(this.value == "") {
              dtable.search("").draw();
          }
          return;
      });
    },
    handleOk(bvModalEvt) {
      var self = this;
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      self.save();
    },
    save(){
      var self = this;
      self.$validator.validateAll().then(result => {
          if (result) {
            self.saveCampaignData();
          }
      })
      .catch(err => {
          error(err);
      });
    },
    editCampaignDetails(){
      var self = this;
      $(document).on("click", ".editCampaignDetails", function (e) {
        self.edit_id = $(e.currentTarget).attr("data-user-id");
        self.openModal('edit');
        self.getCampaignData();
      });
    },
    getFormatedErrorMessage(field, fieldTitle, segment = false, segmentNo){
        let message = ''
        this.errors.items.forEach((error, i) => {
          if(error.field == field){
            if(error.rule == 'required'){
                if(segment === true){
                    message = fieldTitle + ' is a mandatory field. Please fill it up in Wheel Segment ' + parseInt(segmentNo+1) + '.'
                }else{
                    message = fieldTitle + ' is a mandatory field. Please fill it up.'
                }
            }
            if(error.rule == 'max'){
                message = 'The '+ fieldTitle +' field may not be greater than 24 characters'
            }
            if(error.rule == 'min'){
                message = 'The '+ fieldTitle +' field must be at least 3 characters'
            }
            if(error.rule == 'min_value'){
                message = error.msg.replace(error.field, fieldTitle)
            }
            if(error.rule == 'max_value'){
                message = error.msg.replace(error.field, fieldTitle)
            }
          }
        });
        return message
    },
    saveCampaignData() {
      var self = this;
      // Format store id
      var storeId = [];
      var states = [];
      var cpId = [];
      if(self.stores.length > 0)
      {
        self.selectedStores.filter((item) => {
          storeId.push(item.id);
          states.push(item.state);
        });
        self.selectedCorporateParents.filter((item) => {
          cpId.push(item.user_id);
        });
        self.selectedCPId = cpId;
      }
      
        var request = {
          edit_id: self.campaignDetails.id,
          campaign_id: self.campaignDetails.campaign_id,
          campaign_name: self.campaignDetails.campaign_name,
          status_id: self.campaignDetails.status_id,
          use_as_generic_points: 1,
          brand_id: self.selectedBrand.id,
          state: states,
          stores: storeId,
          created_by: self.userDetails.user_id,
          updated_by: self.userDetails.user_id,
          user_id: self.selectedCPId,
        };

        console.log(request);
        
        campaign
        .addEditCampaign(request)
        .then(response => {
          if(response.code == 200){
            success(response.message);
            self.hideModal('sm-modal');
            self.loadDT();
            // Reset the fields
            self.campaignDetails = [];
            self.selectedBrand = [];
            self.selectedCorporateParents = [];
            self.selectedStores = [];
            self.selectedStates = [];
            self.$validator.reset();
          }
        })
        .catch(err => {
            if(err.response.data.message){
                error(err.response.data.message);
            }else{
                error(err);
            }
        });
    },
    hideModal(modal){
      var self = this;
      self.$bvModal.hide(modal);
    },
    getCampaignData() {
      var self = this;
      self.fetchingLoader = true;
      self.fetchingLoaderText = "Please wait...";
      
      var request = {
        id: self.edit_id
      };
    
      campaign
      .getCampaignData(request)
      .then(response => {
        if(response.code == 200){
          self.selectedBrand = response.data.stores;
          self.campaignDetails.id = response.data.campaign.id;
          self.campaignDetails.brand_id = response.data.campaign.brand_id;
          self.campaignDetails.campaign_id = response.data.campaign.campaign_id;
          self.campaignDetails.campaign_name = response.data.campaign.campaign_name;
          self.campaignDetails.status_id = response.data.campaign.status_id;
          self.campaignDetails.use_as_generic_points = response.data.campaign.use_as_generic_points == 0 ? 1 : 0;
          self.setStates(response.data.stores);
          self.setCorporateParents(response.data.stores);
          self.setBrand(response.data.campaign.brand_id);
          setTimeout(function () {
            self.setStores(response.data.stores);
            self.fetchingLoader = false;
          }, 3000);
        }
      })
      .catch(err => {
        console.log(err);
            if(err.response.data.message){
                error(err.response.data.message);
            }else{
                error(err);
            }
        });
    },
    setStates(campaignStores){
        this.selectedStates = []
        campaignStores.forEach(campaignStore => {
            this.selectedStates.push(this.states[0].data.find(state => state.state == campaignStore.state))
        });

        // remove duplicates from state array
        this.selectedStates = this.selectedStates.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.state === value.state
            ))
        )
    },
    setBrand(brandId){
      this.selectedBrand = []
      const selectedBrand = this.brandList.find(brand => brand.id === brandId);

      if (selectedBrand) {
          this.selectedBrand = selectedBrand;
          console.log("Selected Brand:", this.selectedBrand);
      } else {
          console.error("Brand not found for the given brand_id:", brandId);
      }
    },
    setCorporateParents(rewardStores){
      this.selectedCorporateParents = []
        rewardStores.forEach(rewardStore => {
          const matchedParent = this.corporateParents[0].data.find(cp => cp.user_id == rewardStore.user_id);
          // console.log(matchedParent);

          if (matchedParent) {
            console.log("Matching data for user_id:", rewardStore.user_id, matchedParent);
            this.selectedCorporateParents.push(matchedParent);
          } else {
            console.log("No match found for user_id:", rewardStore.user_id);
          }
          
          // this.selectedCorporateParents.push(matchedParent);
        });

        // remove duplicates from cp array
        this.selectedCorporateParents = this.selectedCorporateParents.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.user_id === value.user_id
            ))
        )
    },
    setStores(rewardStores){
      console.log(this.stores);
      
        this.selectedStores = []
        rewardStores.forEach(rewardStore => {
          const matchedParent = this.stores[0].data.find(store => store.id == rewardStore.id);
          // console.log(matchedParent);

          if (matchedParent) {
            console.log("Matching data for store_id:", rewardStore.id, matchedParent);
            this.selectedStores.push(matchedParent);
          } else {
            console.log("No match found for store_id:", rewardStore.id);
          }
          
          // this.selectedCorporateParents.push(matchedParent);
        });

        // remove duplicates from cp array
        this.selectedStores = this.selectedStores.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
        )
    },
    //get the autorized merchant list
    getAuthorizedStores() {
      var self = this;
      var request = {
        state: self.selectedStates,
        type: 'brand'
      };
      campaign
        .getauthorizedstores(request)
        .then(function (response) {
          if (response.code == 200) {
            self.brandList = response.data;
            if (self.type != "edit") {
              // self.selectedBrand = self.brandList;
            }
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          error(error);
           });
    },
    storeCustomName (data) {
      return data && data.retailer ? data.retailer + ' - ' + data.state : ''
    },
    getAllCorporateParents(){
        return new Promise((resolve, reject) => {
            var self = this;
            self.loading = true;
            campaign
            .getAllCorporateParents()
            .then(response => {
                if (response.code == 200) {
                    self.corporateParents = [{
                        label: 'Select All Corporate Parents',
                        data: response.data
                    }]
                    resolve(true)
                } else {
                    error(response.message);
                    reject(false)
                }
                self.loading = false;
            })
            .catch(err => {
                self.loading = false;
                error(err);
                reject(false)
            });
        })
    },
    getAllStores(states, cp_ids, selectedStores = null){
      return new Promise((resolve, reject) => {
          var self = this;
          self.disableStore = true
          self.loading = true;
          campaign
          .getAllStores(states, cp_ids)
          .then(response => {
              if (response.code == 200) {

                if(response.data.length > 0){
                  self.disableStore = false
                  self.stores = [{
                    label: 'Select All Stores',
                    data: response.data
                  }]
                  if(selectedStores != null && selectedStores.length > 0){
                    self.selectedStores = []
                    selectedStores.forEach((store, i) => {
                        self.selectedStores.push(response.data.filter(st => st.id == store.store_id)[0])
                    });
                  }
                  resolve(true)
                }else{
                  self.selectedStores = []
                  error('No stores found!')
                  reject(false)
                }
              } else {
                self.disableStore = false
                error(response.message);
                reject(false)
              }
              self.fetchingLoader = false
              self.loading = false;
          })
          .catch(err => {
            self.fetchingLoader = false
            self.loading = false;
            error(err);
            reject(false)
          });
      });
    },
    cpCustomName (data) {
      return data.first_name
    },
    getStatus() {
    var self = this;
    api
      .getStatus()
      .then((response) => {
        if ((response.code = 200)) {
          response.data.forEach((field, index) => {
            if (field.code == '204') {
              self.activeStatusID = field.id;
            } else if (field.code == '205') {
              self.inactiveStatusID = field.id;
            }
          });
        } else {
          error(response.message);
        }
      })
      .catch((err) => {
        error(err);
      });
    },
    //get the autorized merchant list
    getAllStates() {
      var self = this;
      campaign
        .getAllStates()
        .then(function (response) {
          if (response.code == 200) {
            self.states = [{
                label: 'Select All States',
                data: response.data
            }]
          } else {
            error(response.message);
          }
        })
        .catch(function (error) {
          error(error);
           });
    },
    openModal(type) {
      var self = this;
      self.fetchingLoaderText = "Please wait! we're fetching all campaign details";
      self.fetchingLoader = true;
      self.selectedCorporateParents = [];
      self.selectedStates = [];
      self.selectedBrand = null;
      self.stores = [];
      self.selectedStores = [];
      self.type = type;
      if (type == "edit") {
        self.modalTitle = "Edit Campaign";
        self.fetchingLoader = false;
        self.$bvModal.show("sm-modal");
      } else {
        self.modalTitle = "Add Campaign";
        // Reset Form Fields
        self.campaignDetails = {
          campaign_id:"",
          campaign_name:"",
          tax:"",
          status_id:"",
          use_as_generic_points:1,
          stores: [],
          state : []
        }
        self.fetchingLoader = false;
        self.$bvModal.show("sm-modal");
      }
    },
    stateCustomName (data) {
      return data.state
    },
    customBrandSelectLabel(brand) {
      return brand && brand.retailer ? brand.retailer + ' - ' + brand.state : ''
    },
  }
};
</script>
<style>
.form-group {
  position: relative;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent the icon from blocking input interactions */
  color: #888; /* Customize color as needed */
  font-size: 16px; /* Adjust font size as needed */
}

.form-control.pr-4 {
  padding-right: 30px; /* Make space for the icon */
}

</style>